import { SHOW_LOADING, UPDATE_SIDE_BAR } from '../types';

export const controlLoading = value => ({
  type: SHOW_LOADING,
  payload: value,
});

export const sideBarHandler = value => ({
  type: UPDATE_SIDE_BAR,
  payload: value,
});
