import { GET_CURRENT_DRAFTS, GET_OPEN_QUOTES, USER_LOGOUT } from '../types';

const initialState = {
  currentDrafts: null,
  openQuotes: null,
};

// eslint-disable-next-line default-param-last
const mainReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CURRENT_DRAFTS:
      return {
        ...state,
        currentDrafts: payload,
      };
    case GET_OPEN_QUOTES:
      return {
        ...state,
        openQuotes: payload,
      };
    case USER_LOGOUT:
      return {
        currentDrafts: null,
        openQuotes: null,
      };
    default:
      return state;
  }
};

export default mainReducer;
