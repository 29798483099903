import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import { calculateOrderTotal } from '../order/OrderItem';
import { orderStatus } from '../../constants/orderStatus';

const OrderItem = ({ order }) => {
  const navigate = useNavigate();
  const orderTotal = useMemo(() => calculateOrderTotal(order), [order]);
  const isDraft = useMemo(
    () => parseInt(order?.orderType, 10) === orderStatus.DRAFT,
    [order],
  );
  const handleClick = useCallback(
    id => {
      if (isDraft) {
        navigate(`/order/products/${id}`);
      } else {
        navigate(`/order/review/${id}`);
      }
    },
    [navigate, isDraft],
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 200,
          height: 200,
        },
      }}>
      <Paper
        className="main-order-item-box"
        onClick={() => handleClick(order.id)}>
        <h4>Draft: {order.draftNo}</h4>
        <div>PO: {order.poNumber}</div>
        <div>Total: ${orderTotal?.toFixed(2)}</div>
        <div>Style: {order?.orderstyles?.[0]?.doorcolor?.name}</div>
      </Paper>
    </Box>
  );
};

export default OrderItem;
