import errorsReturn from '../errorsReturn';
import {
  UPDATE_EMAIL_SENT_COUNT,
  USER_LOGOIN_SUCCESS,
  USER_LOGOUT,
  USER_UPDATE_TOKEN,
} from '../types';
import { controlLoading } from './loadingActions';
import getErrors from './errorActions';
import request from '../../config/request';
import createMessage from './messageActions';

export const logout = () => dispatch => {
  dispatch({ type: USER_LOGOUT });
};

export const login = values => async dispatch => {
  try {
    dispatch(controlLoading(true));
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await request.post(`customer/login`, values, config);
    dispatch({
      type: USER_LOGOIN_SUCCESS,
      payload: {
        userInfo: data.data.customer,
        userToken: data.data.token,
      },
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const resendEmail = id => async (dispatch, getState) => {
  const {
    auth: { emailSentCount },
  } = getState();
  if (emailSentCount <= 3) {
    try {
      dispatch(controlLoading(true));
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      await request.get(`customer/resendconfirmemail/${id}`, config);
      dispatch({
        type: UPDATE_EMAIL_SENT_COUNT,
        payload: emailSentCount + 1,
      });
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  } else {
    dispatch(getErrors('Maximum retry attempts exceeded.'));
  }
};

export const updatePassword =
  (values, setUpdatePasswordOpen) => async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
      } = getState();
      await request.post(`customer/resetpassword`, values, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      dispatch(createMessage('Updated password successfully'));
      setUpdatePasswordOpen(false);
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const resetForgetPassword = (values, navigate) => async dispatch => {
  try {
    dispatch(controlLoading(true));
    await request.post(`customer/resetforgetpassword`, values, {
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch(createMessage('Updated password successfully'));
    navigate('/login');
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const forgetPassword = (values, navigate) => async dispatch => {
  try {
    dispatch(controlLoading(true));
    await request.post(`customer/forgetpassword`, values, {
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch(createMessage('Please check your email'));
    navigate('/login');
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const renewUserToken = values => dispatch => {
  const newTokenValue = values.headers.authorization;
  dispatch({
    type: USER_UPDATE_TOKEN,
    payload: newTokenValue,
  });
};
