import { Container } from '@mui/material';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import AppForm from './form/AppForm';
import AppInput from './form/AppInput';
import { siteKey } from '../config/default';
import SubmitButton from './form/SubmitButton';
import { updatePassword } from '../store/actions/authActions';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required()
    .min(8, 'Must be at least 8 characters')
    .label('Old Password'),
  password: Yup.string()
    .required()
    .min(8, 'Must be at least 8 characters')
    .label('Password'),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .label('Confirm Password'),
});

const UpdatePassword = ({ setUpdatePasswordOpen }) => {
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);
  return (
    <Container>
      <div className="login-box">
        <h2 style={{ textAlign: 'center', padding: '20px' }}>
          Update Password
        </h2>
        <AppForm
          initialValues={{
            oldPasswordL: '',
            password: '',
            confirmPassword: '',
          }}
          onSubmit={async values => {
            const googleRes = await recaptchaRef?.current?.executeAsync();
            recaptchaRef?.current?.reset();
            const updateValues = { ...values, googleRes };
            console.log('updateValues ==>', updateValues);
            dispatch(updatePassword(updateValues, setUpdatePasswordOpen));
          }}
          validationSchema={validationSchema}>
          <AppInput
            name="oldPassword"
            label="Old Password"
            type="password"
            required
          />
          <AppInput
            name="password"
            label="New Password"
            type="password"
            required
          />
          <AppInput
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            required
          />
          <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={siteKey} />
          <SubmitButton title="Update Password" />
        </AppForm>
      </div>
    </Container>
  );
};

export default UpdatePassword;
