import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Button } from '@mui/material';

function SubmitButton({ title }) {
  const { handleSubmit } = useFormikContext();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleButtonClick = () => {
    if (!isButtonDisabled) {
      setIsButtonDisabled(true);
      handleSubmit();
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="form-submit-button">
      <Button
        variant="contained"
        size="large"
        onClick={handleButtonClick}
        fullWidth
        style={{ color: '#fff' }}
        disabled={isButtonDisabled}>
        {title}
      </Button>
    </div>
  );
}

export default SubmitButton;

SubmitButton.propTypes = {
  title: PropTypes.string.isRequired,
};
