/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormikContext } from 'formik';
import { TextField } from '@mui/material';

function AppInput({ name, type, label, required, ...otherProps }) {
  const { setFieldTouched, handleChange, values, errors, touched } =
    useFormikContext();
  return (
    <div className="form-input">
      <TextField
        fullWidth
        error={!!(touched[name] && errors[name])}
        required={required}
        name={name}
        type={type}
        id={name}
        label={label}
        variant="outlined"
        value={values[name]}
        onChange={handleChange(name)}
        onBlur={() => setFieldTouched(name)}
        helperText={touched[name] && errors[name] ? errors[name] : null}
        {...otherProps}
      />
    </div>
  );
}

export default AppInput;
