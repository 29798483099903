import React from 'react';
import { Container } from '@mui/material';
import Layout from '../components/Layout';
import RecentOrders from '../components/main/RecentOrders';
import OpenQuotes from '../components/main/OpenQuotes';
import { titleList } from '../constants/logoList';

const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth';

function Main() {
  return (
    <Layout>
      <Container>
        <div className="main-title">
          <h1>Welcome to the {titleList?.[CLIENT]?.TITLE}</h1>
        </div>
        <RecentOrders />
        <OpenQuotes />
      </Container>
    </Layout>
  );
}

export default Main;
