/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { Container, Divider, Paper } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppForm from '../components/form/AppForm';
import AppInput from '../components/form/AppInput';
import SubmitButton from '../components/form/SubmitButton';
import uploadCloudinary from '../components/cloudinary/uploadCloudinary';
import getErrors from '../store/actions/errorActions';
import request from '../config/request';
import AppSelect from '../components/form/AppSelect';
import DragDrop from '../components/cloudinary/DragDrop';
import { siteKey } from '../config/default';
import selectList from '../constants/selectList.json';

const startYearList = totalYear => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = 0; i < totalYear; i += 1) {
    years.push(currentYear - i);
  }

  return years;
};

const validationSchema = Yup.object().shape({
  businessNumber: Yup.string()
    .required()
    .min(2)
    .max(80)
    .label('Business Number'),
  firstName: Yup.string().required().min(2).max(50).label('First Name'),
  lastName: Yup.string().required().min(2).max(50).label('Last Name'),
  address: Yup.string().required().max(200).label('Address'),
  addressLine2: Yup.string().max(100).label('Apt or Suit #'),
  city: Yup.string().required().max(40).label('City'),
  province: Yup.string().required().max(80).label('Province'),
  postCode: Yup.string().required().max(40).label('Postal Code'),
  country: Yup.string().required().max(40).label('Country'),
  phone: Yup.string().required().min(8).max(20).label('Phone'),
  fax: Yup.string().min(8).max(20).label('Fax'),
  email: Yup.string().required().email().label('Email'),
  company: Yup.string().required().min(2).max(80).label('Company'),
  website: Yup.string().max(180).label('Website'),
  startupYear: Yup.string().required().max(180).label('Start up year'),
  businessType: Yup.string().required().max(180).label('Business Type'),
  occupation: Yup.string().required().max(40).label('Occupation'),
  joinSource: Yup.string().max(40).label('Join Source'),
  businessLicense: Yup.string().max(250).label('Business License'),
  contactFirstName: Yup.string()
    .required()
    .min(2)
    .max(50)
    .label('Contract First Name'),
  contactLastName: Yup.string()
    .required()
    .min(2)
    .max(50)
    .label('Contract Last Name'),
  position: Yup.string().required().min(2).max(30).label('Position'),
  contactPhone: Yup.string().required().label('Contract Phone'),
  contactEmail: Yup.string().required().email().label('Contract Email'),
  businessCard: Yup.string().max(250).label('Business Card'),
  driverLicense: Yup.string().max(250).label('Driver License'),
});

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const [businessCard, setBusinessCard] = useState(null);
  const [driverLicense, setDriverLicense] = useState(null);
  const [businessLicense, setBusinessLicense] = useState(null);

  return (
    <Container>
      <h1 className="signin-header">Dealer Application Form</h1>
      <Paper max-width="md" className="signin-paper">
        <AppForm
          initialValues={{
            accountName: '',
            businessNumber: '',
            firstName: '',
            lastName: '',
            address: '',
            addressLine2: '',
            city: '',
            province: '',
            postCode: '',
            country: '',
            phone: '',
            fax: '',
            email: '',
            company: '',
            website: '',
            startupYear: '',
            businessType: '',
            occupation: '',
            joinSource: '',
            businessLicense: '',
            contactFirstName: '',
            contactLastName: '',
            position: '',
            contactPhone: '',
            contactEmail: '',
            businessCard: '',
            driverLicense: '',
          }}
          onSubmit={async values => {
            const googleRes = await recaptchaRef?.current?.executeAsync();
            recaptchaRef?.current?.reset();
            const updatedValues = { ...values };
            if (businessCard) {
              const businessCardLink = await uploadCloudinary(
                businessCard,
                dispatch,
              );
              updatedValues.businessCard = businessCardLink;
            }

            if (driverLicense) {
              const driverLicenseLink = await uploadCloudinary(
                driverLicense,
                dispatch,
              );
              updatedValues.driverLicense = driverLicenseLink;
            }

            if (businessLicense) {
              const businessLicenseLink = await uploadCloudinary(
                businessLicense,
                dispatch,
              );
              updatedValues.businessLicense = businessLicenseLink;
            }

            updatedValues.googleRes = googleRes;
            try {
              const res = await request.post(`customer/create/`, updatedValues);
              if (res.data.code === 201) {
                navigate(`/signinsuccess/${res.data.data}`);
              }
            } catch (e) {
              let displayError = 'Create user failed';
              if (e?.response?.data?.msg) {
                displayError = e?.response?.data?.msg;
              }
              dispatch(getErrors(displayError));
            }
          }}
          validationSchema={validationSchema}>
          <h2 className="sigin-h2">Your Company Info</h2>
          <AppInput
            name="company"
            label="Company"
            type="text"
            required
            inputprops={{ maxLength: 100 }}
          />
          <AppInput
            name="businessNumber"
            label="Business Number"
            type="text"
            required
            inputprops={{ maxLength: 80 }}
          />
          <div className="sigin-name">
            <div className="sigin-name-item">
              <AppInput
                name="firstName"
                label="Owners First Name"
                type="text"
                inputprops={{ maxLength: 50 }}
                required
              />
            </div>
            <div className="sigin-name-item">
              <AppInput
                name="lastName"
                label="Owners Last Name"
                type="text"
                inputprops={{ maxLength: 50 }}
                required
              />
            </div>
          </div>
          <AppInput
            name="address"
            label="Address"
            type="text"
            required
            inputprops={{ maxLength: 200 }}
          />
          <AppInput
            name="addressLine2"
            label="Apt or Suit #"
            type="text"
            inputprops={{ maxLength: 200 }}
          />
          <AppInput
            name="city"
            label="City"
            type="text"
            required
            inputprops={{ maxLength: 40 }}
          />
          <AppInput
            name="province"
            label="Province"
            type="text"
            required
            inputprops={{ maxLength: 80 }}
          />
          <AppInput
            name="postCode"
            label="Postal Code"
            type="text"
            required
            inputprops={{ maxLength: 40 }}
          />
          <AppSelect
            name="country"
            label="Country"
            required
            options={selectList.country.list}
          />

          <AppInput
            name="phone"
            label="Phone"
            type="text"
            required
            inputprops={{ maxLength: 20 }}
          />
          <AppInput
            name="fax"
            label="Fax"
            type="text"
            inputprops={{ maxLength: 30 }}
          />
          <AppInput name="email" label="Email" type="text" required />
          <AppInput
            name="website"
            label="Website"
            type="text"
            inputprops={{ maxLength: 200 }}
          />
          <Divider />
          <h2 className="sigin-h2">Contact Information</h2>
          <div className="sigin-name">
            <div className="sigin-name-item">
              <AppInput
                name="contactFirstName"
                label="First Name"
                type="text"
                inputprops={{ maxLength: 50 }}
                required
              />
            </div>
            <div className="sigin-name-item">
              <AppInput
                name="contactLastName"
                label="Last Name"
                type="text"
                inputprops={{ maxLength: 50 }}
                required
              />
            </div>
          </div>

          <AppSelect
            name="position"
            label="Position"
            required
            options={selectList.position.list}
          />
          <AppInput
            name="contactPhone"
            label="Phone"
            type="text"
            required
            inputprops={{ maxLength: 20 }}
          />
          <AppInput name="contactEmail" label="Email" type="text" required />
          <DragDrop
            file={businessCard}
            setFile={setBusinessCard}
            label="Upload your business card"
          />
          <DragDrop
            file={driverLicense}
            setFile={setDriverLicense}
            label="Upload your driver license"
          />

          <Divider />
          <h2 className="sigin-h2">Company Details</h2>
          <AppSelect
            name="startupYear"
            label="Business Startup Year"
            required
            options={startYearList(30)}
          />
          <AppSelect
            name="businessType"
            label="Type of Business"
            required
            options={selectList.businessType.list}
          />
          <AppSelect
            name="occupation"
            label="I am a/an"
            required
            options={selectList.occupation.list}
          />
          <DragDrop
            file={businessLicense}
            setFile={setBusinessLicense}
            label="Upload a copy of your Business License"
          />
          <Divider />
          <h2 className="sigin-h2">How did you hear about us?</h2>
          <AppInput
            name="joinSource"
            label="If you were referred by a person with description OR with our Sales Rep."
            type="text"
            inputprops={{ maxLength: 200 }}
          />
          <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={siteKey} />
          <SubmitButton title="Submit" />
        </AppForm>
        <div className="login-link">
          <Link to="/login">Already have an account?</Link>
        </div>
      </Paper>
    </Container>
  );
}

export default SignIn;
