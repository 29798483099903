import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = ['Info', 'Products', 'Shipping', 'Review'];

function OrderStep({ currentStep }) {
  return (
    <Box sx={{ width: '100%' }} style={{ marginTop: '10px' }}>
      <Stepper activeStep={currentStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default OrderStep;
