import errorsReturn from '../errorsReturn';
import {
  GET_AGENT_INFO,
  GET_ALL_CUSTOMER_SHIPPING_ADDRESSES,
  GET_ALL_ORDERS,
  GET_ALL_WAREHOUSES,
  GET_CATEGORIES,
  GET_ONE_ORDER,
  GET_ORDER_COUNTS,
  GET_SEARCH_RPODUCT,
  UPDATE_ALL_ORDERS,
} from '../types';
import { controlLoading } from './loadingActions';
import getErrors from './errorActions';
import request from '../../config/request';
import { logout, renewUserToken } from './authActions';
import modifyOrderItemOption from '../../utils/modifyOrderItemDoorDirection';

export const getCategories = () => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();
    const res = await request.get(`order/category`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));
    dispatch({
      type: GET_CATEGORIES,
      payload: res.data.data,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const getOrderCounts = () => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.get(`order/ordercounts`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));
    dispatch({
      type: GET_ORDER_COUNTS,
      payload: res.data.data,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const getOneOrder = id => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.get(`order/getoneorder/${id}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));
    dispatch({
      type: GET_ONE_ORDER,
      payload: res.data.data,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const getAllOrders =
  (keyword, pageNumber, status) => async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
      } = getState();

      const res = await request.get(
        `order/getorders?keyword=${keyword}&pageNumber=${pageNumber}&status=${status}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        },
      );
      dispatch(renewUserToken(res));
      const values = {
        orders: res.data.data,
        pages: res.data.pages,
        count: res.data.count,
      };
      dispatch({
        type: GET_ALL_ORDERS,
        payload: values,
      });
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const createOrder = (values, navigate) => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.post(`order/createorder`, values, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));
    navigate(`/order/products/${res.data.data}`);
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const updateOrder = (values, navigate) => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.put(`order/updateorder/${values.id}`, values, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));
    navigate(`/order/products/${values.id}`);
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const deleteOrder = id => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
      order: { orders },
    } = getState();

    const res = await request.delete(`order/deleteorder/${id}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));
    const tempOrders = orders.filter(order => order.id !== id);
    dispatch({
      type: UPDATE_ALL_ORDERS,
      payload: tempOrders,
    });
    dispatch(getOrderCounts());
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const addOrderStyle =
  (doorcolorId, orderId) => async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
      } = getState();

      const values = { orderId, doorcolorId };
      const res = await request.post(`order/addorderstyle`, values, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      dispatch(renewUserToken(res));
      dispatch(getOneOrder(orderId));
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const updateOrderStyleName =
  (values, orderId) => async (dispatch, getState) => {
    console.log('called');
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
      } = getState();

      const res = await request.put(
        `order/updateorderstylename/${values.id}`,
        values,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        },
      );
      dispatch(renewUserToken(res));
      dispatch(getOneOrder(orderId));
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const deleteOrderStyle = (id, orderId) => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.delete(`order/deleteorderstyle/${id}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));
    dispatch(getOneOrder(orderId));
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const updateOrderStyle =
  (values, orderId) => async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
      } = getState();

      const res = await request.put(
        `order/updateorderstyle/${values.id}`,
        values,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        },
      );
      dispatch(renewUserToken(res));
      dispatch(getOneOrder(orderId));
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const searchProduct = values => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.post(`order/searchproduct`, values, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));
    const selectValues = res.data.data.map(item => ({
      value: item.id,
      label: item.product.sku,
    }));
    // console.log('selectValues ==>', selectValues);
    dispatch({
      type: GET_SEARCH_RPODUCT,
      payload: selectValues,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const addOrderItem =
  (values, orderStyleId, orderId) => async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
      } = getState();

      const sendValues = { ...values, orderStyleId };
      const res = await request.post(`order/addorderitem`, sendValues, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      dispatch(renewUserToken(res));
      dispatch(getOneOrder(orderId));
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const addOrderSingleItem =
  (values, orderStyleId, orderId) => async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
      } = getState();

      const sendValues = { ...values, orderStyleId };
      const res = await request.post(`order/addordersingleitem`, sendValues, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      dispatch(renewUserToken(res));
      dispatch(getOneOrder(orderId));
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const deleteOrderItem = (id, orderId) => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.delete(`order/deleteorderitem/${id}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));
    dispatch(getOneOrder(orderId));
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const updateDoorDirection = values => async (dispatch, getState) => {
  const { doorDirection, orderItemId, orderStyleId } = values;
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
      order: { order },
    } = getState();

    const res = await request.put(
      `order/updatedoordirection/${orderItemId}`,
      values,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      },
    );
    dispatch(renewUserToken(res));
    const tempOrder = modifyOrderItemOption(
      order,
      orderStyleId,
      orderItemId,
      'doorDirection',
      doorDirection,
    );

    dispatch({
      type: GET_ONE_ORDER,
      payload: tempOrder,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const updateFinishedOption = values => async (dispatch, getState) => {
  const { finishedOption, orderItemId, orderStyleId } = values;
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
      order: { order },
    } = getState();

    const res = await request.put(
      `order/updatefinishedoption/${orderItemId}`,
      values,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      },
    );
    dispatch(renewUserToken(res));
    const tempOrder = modifyOrderItemOption(
      order,
      orderStyleId,
      orderItemId,
      'finishedOption',
      finishedOption,
    );

    dispatch({
      type: GET_ONE_ORDER,
      payload: tempOrder,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const updateItemQty = values => async (dispatch, getState) => {
  const { quantity, orderItemId, orderStyleId } = values;
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
      order: { order },
    } = getState();

    const res = await request.put(
      `order/updateitemqty/${orderItemId}`,
      values,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      },
    );
    dispatch(renewUserToken(res));
    const tempOrder = modifyOrderItemOption(
      order,
      orderStyleId,
      orderItemId,
      'quantity',
      quantity,
    );

    dispatch({
      type: GET_ONE_ORDER,
      payload: tempOrder,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const updateItemAssembly = values => async (dispatch, getState) => {
  const { assemblyRequest, orderItemId, orderStyleId } = values;
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
      order: { order },
    } = getState();

    const res = await request.put(
      `order/updateitemassembly/${orderItemId}`,
      values,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      },
    );
    dispatch(renewUserToken(res));
    const tempOrder = modifyOrderItemOption(
      order,
      orderStyleId,
      orderItemId,
      'assemblyRequest',
      assemblyRequest,
    );

    dispatch({
      type: GET_ONE_ORDER,
      payload: tempOrder,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const updateItemSort = values => async (dispatch, getState) => {
  const {
    reSort: { startIndex, endIndex },
    orderStyleId,
  } = values;
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
      order: { order },
    } = getState();

    const orderStyle = order.orderstyles.find(
      style => style.id === orderStyleId,
    );

    if (orderStyle) {
      const [removedItem] = orderStyle.orderitems.splice(startIndex, 1);
      orderStyle.orderitems.splice(endIndex, 0, removedItem);
    }

    const newSort = orderStyle.orderitems?.map((orderItem, index) => ({
      id: orderItem.id,
      sort: index + 1,
    }));

    const payloads = { newSort };

    const res = await request.put(`order/updateorderitemsort`, payloads, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));

    const newOrder = [...order];

    dispatch({
      type: GET_ONE_ORDER,
      payload: newOrder,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const addItemNote = values => async (dispatch, getState) => {
  const { note, orderItemId, orderStyleId } = values;
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
      order: { order },
    } = getState();

    const res = await request.post(`order/additemnote/${orderItemId}`, values, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));
    const tempOrder = modifyOrderItemOption(
      order,
      orderStyleId,
      orderItemId,
      'note',
      note,
    );

    dispatch({
      type: GET_ONE_ORDER,
      payload: tempOrder,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const getAllCustomerShippingAddresses =
  () => async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
      } = getState();

      const res = await request.get(`order/customershippingaddresses`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      dispatch(renewUserToken(res));

      dispatch({
        type: GET_ALL_CUSTOMER_SHIPPING_ADDRESSES,
        payload: res.data.data,
      });
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const addCustomerShippingAddress =
  values => async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
        order: { customerShippingAddresses },
      } = getState();

      const res = await request.post(
        `order/createcustomershippingaddress`,
        values,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        },
      );
      dispatch(renewUserToken(res));
      const tempValues = [...customerShippingAddresses, res.data.data];
      dispatch({
        type: GET_ALL_CUSTOMER_SHIPPING_ADDRESSES,
        payload: tempValues,
      });
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const updateCustomerShippingAddress =
  values => async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
        order: { customerShippingAddresses },
      } = getState();

      const res = await request.put(
        `order/updatecustomershippingaddress/${values.id}`,
        values,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        },
      );
      dispatch(renewUserToken(res));
      const tempValues = customerShippingAddresses.map(address =>
        address.id === values.id ? values : address,
      );
      dispatch({
        type: GET_ALL_CUSTOMER_SHIPPING_ADDRESSES,
        payload: tempValues,
      });
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const deleteCustomerShippingAddress =
  id => async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
        order: { customerShippingAddresses },
      } = getState();

      const res = await request.delete(
        `order/deletecustomershippingaddress/${id}`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        },
      );
      dispatch(renewUserToken(res));
      const tempValues = customerShippingAddresses.filter(
        address => address.id !== id,
      );
      dispatch({
        type: GET_ALL_CUSTOMER_SHIPPING_ADDRESSES,
        payload: tempValues,
      });
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const orderShippingAddress =
  (values, navigate) => async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
      } = getState();

      const res = await request.post(`order/ordershippingaddress`, values, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      dispatch(renewUserToken(res));

      navigate(`/order/review/${values.orderId}`);
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const orderPickUp = (values, navigate) => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.post(`order/orderpickup`, values, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));

    navigate(`/order/review/${values.id}`);
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const getAllWarehouses = () => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.get(`order/warehouses`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));

    dispatch({
      type: GET_ALL_WAREHOUSES,
      payload: res.data.data,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const draftOrderFinished =
  (id, navigate) => async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
      } = getState();

      const res = await request.put(`order/draftorderfinished/${id}`, id, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      dispatch(renewUserToken(res));
      dispatch(getOrderCounts());
      navigate(`/order`);
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };

export const quoteToDraft = (id, navigate) => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.put(
      `order/customerreturnordertodraft/${id}`,
      id,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      },
    );
    dispatch(renewUserToken(res));
    dispatch(getOrderCounts());
    navigate(`/order`);
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const approvedQuote = (id, order) => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.put(
      `order/approvedquote/${id}`,
      { orderData: order },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      },
    );
    dispatch(renewUserToken(res));
    dispatch(getOneOrder(id));
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const getAgentInfo = () => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.get(`order/agentinfo`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));
    dispatch({
      type: GET_AGENT_INFO,
      payload: res.data.data,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};
