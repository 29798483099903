/* eslint-disable react/prop-types */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  ListItemIcon,
  MenuItem,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import React, { useCallback, useMemo, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import EditIcon from './EditIcon';
import AppModal from '../AppModal';
import { deleteOrder } from '../../store/actions/orderActions';
import {
  orderHeaderList,
  orderStatus,
  quoteTypeList,
} from '../../constants/orderStatus';

const CardItem = ({ title, value }) => (
  <div style={{ paddingBottom: '10px' }}>
    <span className="order-card-title">{title}</span> {value ?? 'N/A'}
  </div>
);

const CardHeaderIcon = ({ orderId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModelOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const handleDelete = useCallback(() => {
    dispatch(deleteOrder(orderId));
  }, [orderId, dispatch]);
  const handleEdit = useCallback(() => {
    navigate(`/order/editorder/${orderId}`);
  }, [orderId, navigate]);
  return (
    <>
      <AppModal
        open={modalOpen}
        cancelContent="Cancel"
        submitContent="Delete"
        title="Are you sure you want to delete"
        cancelClick={handleModalClose}
        submitClick={handleDelete}
        danger
        handleClose={handleModalClose}
      />
      <EditIcon
        handleClick={handleClick}
        handleClose={handleClose}
        title="Order Item"
        anchorEl={anchorEl}>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <FaEdit fontSize={24} />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem onClick={handleModelOpen}>
          <ListItemIcon>
            <MdDeleteForever fontSize={24} style={{ color: 'red' }} />
          </ListItemIcon>
          Delete
        </MenuItem>
      </EditIcon>
    </>
  );
};

const CustomCardMedia = styled(CardMedia)({
  width: 100,
  height: 200,
  objectFit: 'contain',
});

export const calculateOrderTotal = currentOrder => {
  const totalFinishedEnd =
    currentOrder?.orderitemfinishedends?.reduce(
      (acc, item) => acc + item.quantity * item.price,
      0,
    ) || 0;
  const total =
    currentOrder?.orderstyles?.reduce((acc, style) => {
      const styleTotal = style?.orderitems?.reduce((subtotal, item) => {
        const itemQuantity = item?.quantity || 0;
        const itemPrice = item?.price || 0;
        return subtotal + itemQuantity * itemPrice;
      }, 0);
      return acc + styleTotal;
    }, 0) || 0;

  return total + totalFinishedEnd || 0;
};

function OrderItem({ order }) {
  const orderCurrentType = useMemo(() => {
    let title = orderHeaderList.find(
      orderItem => orderItem.id === parseInt(order.orderType, 10),
    );

    if (
      parseInt(order.orderType, 10) === orderStatus.QUOTE &&
      order.quoteType === quoteTypeList.SENT_TO_CONFIRM
    ) {
      console.log('before =>', title);
      title = { ...title, title: `${title.title} (Need Confirm)` };
      console.log('after =>', title);
    }
    return title;
  }, [order.orderType, order.quoteType]);

  const orderTotal = useMemo(() => calculateOrderTotal(order), [order]);
  const isDraft = useMemo(
    () => parseInt(order?.orderType, 10) === orderStatus.DRAFT,
    [order],
  );

  return (
    <Card
      sx={{ maxWidth: 500, backgroundColor: '#f6f6f6' }}
      className="order-item-card">
      <CardHeader
        subheader={orderCurrentType?.title}
        action={isDraft ? <CardHeaderIcon orderId={order.id} /> : null}
      />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CustomCardMedia
            component="img"
            image={
              order?.orderstyles[0]?.doorcolor?.picLink ||
              'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
            }
            alt="order pic"
          />
          <div className="order-item-pic-name">
            {order?.orderstyles[0]?.doorcolor?.name}
          </div>
        </Grid>
        <Grid item xs={8}>
          <CardContent>
            <CardItem title="PO:" value={order.poNumber} />
            <CardItem
              title="Quote Date:"
              value={moment(order.createdAt).format('MM/DD/YYYY')}
            />
            <CardItem
              title="Total Price:"
              value={`$${orderTotal?.toFixed(2)}`}
            />
          </CardContent>
          <CardActions>
            <Link
              to={
                isDraft
                  ? `/order/products/${order.id}`
                  : `/order/review/${order.id}`
              }>
              <Button variant="outlined">View Quote</Button>
            </Link>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
}

export default OrderItem;
