import React, { useCallback } from 'react';
import { Button, Container, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import colorCode from '../constants/colorCode';
import { resendEmail } from '../store/actions/authActions';

function SigninSuccess() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleResendEmail = useCallback(
    activeId => {
      dispatch(resendEmail(activeId));
    },
    [dispatch],
  );

  return (
    <Container>
      <div className="login-container">
        <Paper
          className="login-box"
          style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
          <div style={{ textAlign: 'center' }}>
            <AiOutlineCheckCircle size={70} color={colorCode.PRIMARY} />
            <h2 style={{ marginTop: '50px' }}>
              Please check your email to activate your account! Thanks
            </h2>
          </div>
          <p className="text-sm text-gray-600 mb-4">
            If you didn&apos;t receive the email, you can click the button below
            to resend the email.
          </p>
          <Button variant="outlined" onClick={() => handleResendEmail(id)}>
            Resend Email
          </Button>
          {/* <div style={{ padding: '10px' }} /> */}
          <Link to="/login">
            <Button color="info" variant="outlined" style={{ width: '100%' }}>
              Back to Login
            </Button>
          </Link>
        </Paper>
      </div>
    </Container>
  );
}

export default SigninSuccess;
