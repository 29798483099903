import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['loading', 'errors', 'messages'],
};

const middleware = [thunk];
const persistedReducer = persistReducer(persistConfig, rootReducer);
const composedEnhancer =
  process.env.REACT_APP_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware);

export default () => {
  const store = createStore(persistedReducer, composedEnhancer);
  const persistor = persistStore(store);
  return { store, persistor };
};
