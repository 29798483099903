// const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth'
const logoList = {
  woodworth: {
    BIG_LOGO:
      'https://res.cloudinary.com/home-space/image/upload/v1705806046/Logo/ww_logo_200x180-b8526767_qxich6.webp',
    PRINT_LOGO:
      'https://res.cloudinary.com/home-space/image/upload/v1705806046/Logo/ww_logo_200x180-b8526767_qxich6.webp',
    LOGO: 'https://res.cloudinary.com/home-space/image/upload/v1705806046/Logo/ww_logo_200x180-b8526767_qxich6.webp',
    NAV_LOGO:
      'https://res.cloudinary.com/woodworth-cabinetry/image/upload/v1657679135/website/logo_gi06se.webp',
  },
  dw: {
    BIG_LOGO:
      'https://res.cloudinary.com/homespace/image/upload/v1728091503/WechatIMG1259_wpjxpn.webp',
    PRINT_LOGO:
      'https://res.cloudinary.com/homespace/image/upload/v1717423280/logo/wood_np7djh.webp',
    // LOGO: 'https://res.cloudinary.com/homespace/image/upload/v1710729764/logo/dw-logo_dxhrfu.webp',
    LOGO: 'https://res.cloudinary.com/homespace/image/upload/v1717423280/logo/wood_np7djh.webp',
    NAV_LOGO:
      'https://res.cloudinary.com/homespace/image/upload/v1728091505/WechatIMG1267_xtrmnx.webp',
  },
};

export default logoList;

export const titleList = {
  woodworth: {
    TITLE: 'WLINK',
  },
  dw: {
    TITLE: 'DWLINK',
  },
};
