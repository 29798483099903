import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container } from '@mui/material';
import Layout from '../../components/Layout';
import { getOneOrder } from '../../store/actions/orderActions';
// import Category from '../../components/order/Category';
import OrderStyle from '../../components/order/OrderStyle';
import OrderStep from '../../components/Step';
import AddNewStyle from '../../components/order/AddNewStyle';

function Products() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { order } = useSelector(state => state.order);
  useEffect(() => {
    if (id) dispatch(getOneOrder(id));
  }, [dispatch, id]);

  return (
    <Layout>
      <Container>
        <div style={{ marginTop: '50px' }} />
        <OrderStep currentStep={1} />
        <div className="order-products-box">
          <Button variant="outlined" color="grey" onClick={() => navigate(-1)}>
            Back
          </Button>
        </div>
        <div className="order-products-box">
          <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <AddNewStyle orderId={order?.id} />
          </div>
          {order && (
            <div className="order-product-items">
              {order?.orderstyles?.map(orderStyle => (
                <OrderStyle
                  key={orderStyle.id}
                  orderStyle={orderStyle}
                  orderId={order.id}
                />
              ))}
            </div>
          )}
          {order?.orderstyles?.flatMap(style =>
            style?.orderitems?.flatMap(orderItem => orderItem.id),
          )?.length > 0 && (
            <div className="order-shipping-button">
              <Link to={`/order/shipping/${id}`}>
                <Button variant="contained" color="primary">
                  Shipping
                </Button>
              </Link>
            </div>
          )}
        </div>
      </Container>
    </Layout>
  );
}

export default Products;
