/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormikContext } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';

function AppSelect({ name, label, options }) {
  const { setFieldTouched, handleChange, values, errors, touched } =
    useFormikContext();
  return (
    <div className="form-input">
      <TextField
        fullWidth
        error={!!(touched[name] && errors[name])}
        name={name}
        id={name}
        value={values[name]}
        variant="outlined"
        select
        label={label}
        onChange={handleChange(name)}
        helperText={touched[name] && errors[name] ? errors[name] : null}
        onBlur={() => setFieldTouched(name)}>
        {options?.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}

export default AppSelect;
