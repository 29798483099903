import {
  GET_AGENT_INFO,
  GET_ALL_CUSTOMER_SHIPPING_ADDRESSES,
  GET_ALL_ORDERS,
  GET_ALL_WAREHOUSES,
  GET_CATEGORIES,
  GET_ONE_ORDER,
  GET_ORDER_COUNTS,
  GET_SEARCH_RPODUCT,
  UPDATE_ALL_ORDERS,
  USER_LOGOUT,
} from '../types';

const initialState = {
  categories: null,
  orderCounts: null,
  order: null,
  orders: null,
  searchItems: [],
  pages: 0,
  count: 0,
  customerShippingAddresses: null,
  warehouses: null,
  agentInfo: null,
};

// eslint-disable-next-line default-param-last
const orderReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case GET_ORDER_COUNTS:
      return {
        ...state,
        orderCounts: payload,
      };
    case GET_ONE_ORDER:
      return {
        ...state,
        order: payload,
      };
    case GET_ALL_ORDERS:
      return {
        ...state,
        orders: payload?.orders,
        pages: payload?.pages,
        count: payload?.count,
      };
    case UPDATE_ALL_ORDERS:
      return {
        ...state,
        orders: payload,
        count: state.count - 1,
      };
    case GET_SEARCH_RPODUCT:
      return {
        ...state,
        searchItems: payload,
      };
    case USER_LOGOUT:
      return {
        categoies: null,
        orderCounts: null,
        order: null,
        orders: null,
        pages: 0,
        searchItems: [],
        customerShippingAddresses: null,
        warehouses: null,
        agentInfo: null,
      };
    case GET_ALL_CUSTOMER_SHIPPING_ADDRESSES:
      return {
        ...state,
        customerShippingAddresses: payload,
      };
    case GET_ALL_WAREHOUSES:
      return {
        ...state,
        warehouses: payload,
      };
    case GET_AGENT_INFO:
      return {
        ...state,
        agentInfo: payload,
      };
    default:
      return state;
  }
};

export default orderReducer;
