import React from 'react';
import AddressForm from './AddressForm';
import { formTypeList } from '../../constants/orderStatus';

const UpdateCustomerShippingAddress = ({ modalClose, editProps }) => (
  <div className="order-shipping-address-box">
    <AddressForm
      formType={formTypeList.UPDATE}
      modalClose={modalClose}
      propsState={editProps}
    />
  </div>
);

export default UpdateCustomerShippingAddress;
