import errorsReturn from '../errorsReturn';
import { GET_CURRENT_DRAFTS, GET_OPEN_QUOTES } from '../types';
import { controlLoading } from './loadingActions';
import getErrors from './errorActions';
import request from '../../config/request';
import { logout, renewUserToken } from './authActions';

export const getCurrentDrafts = () => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.get(`order/getorders?pageSize=4&status=1`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));

    dispatch({
      type: GET_CURRENT_DRAFTS,
      payload: res.data.data,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const getOpenQuotes = () => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.get(`order/getorders?pageSize=4&status=2`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));

    dispatch({
      type: GET_OPEN_QUOTES,
      payload: res.data.data,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};
