import React, { useCallback, useRef, useState } from 'react';
import _ from 'lodash';
import { TextField } from '@mui/material';

const OrderSearch = ({ setkeyword }) => {
  const [search, setSearch] = useState('');

  const updateSearch = useRef(_.debounce(() => {}, 1000));
  const handleSearch = useCallback(
    e => {
      const input = e.target.value;
      setSearch(input);
      updateSearch.current.cancel();
      updateSearch.current = _.debounce(() => setkeyword(input), 1000);
      updateSearch.current();
    },
    [setkeyword],
  );
  return (
    <div className="order-search-box">
      <TextField
        id={Math.random().toString()}
        name="searchKeyword"
        style={{ width: '300px' }}
        value={search}
        onChange={e => handleSearch(e)}
        placeholder="Search Item"
        size="small"
        type="text"
      />
    </div>
  );
};

export default OrderSearch;
