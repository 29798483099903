/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Badge, Button, Container } from '@mui/material';
import {
  getAllOrders,
  getCategories,
  getOrderCounts,
} from '../../store/actions/orderActions';
import Layout from '../../components/Layout';
import { GET_ALL_ORDERS, GET_ONE_ORDER } from '../../store/types';
import { orderHeaderList } from '../../constants/orderStatus';
import OrderItem from '../../components/order/OrderItem';
import PagnationComponent from '../../components/PagnationComponent';
import OrderSearch from '../../components/order/OrderSearch';

function Launch() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page') || 1;
  const keyword = queryParams.get('keyword') || '';
  const status = queryParams.get('status') || 2;

  const { orders, orderCounts, count, pages } = useSelector(
    state => state.order,
  );

  const [search, setSearch] = useState('');

  useEffect(() => {
    navigate(`?page=1&keyword=${search}&status=${status}`);
  }, [search, navigate, status]);

  const fetchDate = useCallback(() => {
    dispatch(getAllOrders(keyword, page, status));
  }, [dispatch, status, keyword, page]);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getOrderCounts());
    dispatch({
      type: GET_ONE_ORDER,
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    fetchDate();
    return () => {
      dispatch({ type: GET_ALL_ORDERS, payload: null });
    };
  }, [fetchDate, dispatch]);

  const changePage = useCallback(
    nextPage => {
      navigate(`?page=${nextPage}&keyword=${keyword}&status=${status}`);
    },
    [navigate, keyword, status],
  );

  return (
    <Layout>
      <div className="order-header-status">
        <Link to="/order/newdraft">
          <Button
            variant="contained"
            color="primary"
            className="btn btn-primary rounded-md">
            New Draft
          </Button>
        </Link>
        {orderCounts &&
          orderHeaderList.map(list => {
            const itemCount =
              orderCounts.find(
                order => parseInt(order.orderType, 10) === list.id,
              )?.count ?? null;

            return (
              <div key={list.id} className="ml-10 relative">
                <Badge badgeContent={itemCount} color="secondary">
                  <Button
                    // variant="outlined"
                    color={`${
                      parseInt(status, 10) === list.id ? 'primary' : 'grey'
                    }`}
                    onClick={() => {
                      navigate(`?page=1&keyword=${search}&status=${list.id}`);
                    }}>
                    {list.title}
                  </Button>
                </Badge>
              </div>
            );
          })}
      </div>
      <div style={{ marginBottom: '50px' }}>
        <Container>
          <OrderSearch setkeyword={setSearch} />
          {orders?.length > 0 && (
            <>
              <div className="order-item-count">Count: {count}</div>
              <div className="order-item-container">
                {orders?.map(order => (
                  <OrderItem key={order.id} order={order} />
                ))}
              </div>
            </>
          )}
          <PagnationComponent
            page={parseInt(page, 10)}
            pages={pages}
            func={changePage}
          />
        </Container>
      </div>
    </Layout>
  );
}

export default Launch;
