import { Divider } from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import AppForm from '../form/AppForm';
import {
  addCustomerShippingAddress,
  updateCustomerShippingAddress,
} from '../../store/actions/orderActions';
import AppSelect from '../form/AppSelect';
import AppInput from '../form/AppInput';
import SubmitButton from '../form/SubmitButton';
import { formTypeList } from '../../constants/orderStatus';
import selectList from '../../constants/selectList.json';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().min(2).max(50).label('First Name'),
  lastName: Yup.string().required().min(2).max(50).label('Last Name'),
  phone: Yup.string().required().max(20).label('Phone Number'),
  email: Yup.string().required().email().max(100).label('Email'),
  address: Yup.string().required().min(2).max(200).label('Address'),
  address1: Yup.string().min(2).max(100).label('Apt or Suit #'),
  city: Yup.string().required().max(40).label('City'),
  province: Yup.string().required().max(80).label('Province'),
  postCode: Yup.string().required().max(40).label('Postal Code'),
  country: Yup.string().required().max(40).label('Country'),
});

const AddressForm = ({ formType, modalClose, propsState = null }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IoMdCloseCircleOutline
          size={30}
          style={{ cursor: 'pointer' }}
          onClick={() => modalClose(false)}
        />
      </div>
      <AppForm
        initialValues={{
          firstName: propsState ? propsState.firstName : '',
          lastName: propsState ? propsState.lastName : '',
          phone: propsState ? propsState.phone : '',
          email: propsState ? propsState.email : '',
          address: propsState ? propsState.address : '',
          address1: propsState ? propsState.address1 : '',
          city: propsState ? propsState.city : '',
          province: propsState ? propsState.province : '',
          postCode: propsState ? propsState.postCode : '',
          country: propsState ? propsState.country : '',
        }}
        onSubmit={async values => {
          if (formType === formTypeList.NEW) {
            dispatch(addCustomerShippingAddress(values));
          } else {
            const updatedValues = { ...propsState, ...values };
            dispatch(updateCustomerShippingAddress(updatedValues));
          }
          modalClose(false);
        }}
        validationSchema={validationSchema}>
        <Divider style={{ marginBottom: '30px' }}>Shipping Address</Divider>
        <AppInput
          name="firstName"
          label="First Name"
          type="text"
          required
          inputprops={{ maxLength: 50 }}
        />
        <AppInput
          name="lastName"
          label="Last Name"
          type="text"
          required
          inputprops={{ maxLength: 50 }}
        />
        <AppInput
          name="phone"
          label="Phone Number"
          type="text"
          required
          inputprops={{ maxLength: 20 }}
        />
        <AppInput
          name="email"
          label="Email"
          type="text"
          required
          inputprops={{ maxLength: 100 }}
        />
        <AppInput
          name="address"
          label="Address"
          type="text"
          required
          inputprops={{ maxLength: 200 }}
        />
        <AppInput
          name="address1"
          label="Apt or Suit #"
          type="text"
          inputprops={{ maxLength: 200 }}
        />
        <AppInput
          name="city"
          label="City"
          type="text"
          required
          inputprops={{ maxLength: 40 }}
        />
        <AppInput
          name="province"
          label="Province"
          type="text"
          required
          inputprops={{ maxLength: 80 }}
        />
        <AppInput
          name="postCode"
          label="Postal Code"
          type="text"
          required
          inputprops={{ maxLength: 40 }}
        />
        <AppSelect
          name="country"
          label="Country"
          required
          options={selectList.country.list}
        />
        <SubmitButton title="Submit" />
      </AppForm>
    </div>
  );
};

export default AddressForm;
