import React from 'react';
import logoList from '../../constants/logoList';

const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth';

function Loader() {
  return (
    <div className="loading-page">
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
        <span className="lds-logo">
          <img
            src={logoList?.[CLIENT]?.LOGO}
            alt="logo"
            className="lds-logo-pic"
          />
        </span>
      </div>
    </div>
  );
}

export default Loader;
