import {
  GET_ALL_PRODUCT_CATEGORIES,
  GET_ONE_PRODUCT,
  GET_STYLE_PRODUCTS,
} from '../types';

const initialState = {
  productCategories: null,
  styleProducts: null,
  product: null,
};

// eslint-disable-next-line default-param-last
const specBookReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: payload,
      };
    case GET_STYLE_PRODUCTS:
      return {
        ...state,
        styleProducts: payload,
      };
    case GET_ONE_PRODUCT:
      return {
        ...state,
        product: payload,
      };
    default:
      return state;
  }
};

export default specBookReducer;
