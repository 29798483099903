import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  // TableHead,
  TableRow,
} from '@mui/material';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { VscListUnordered } from 'react-icons/vsc';
import { LiaShippingFastSolid } from 'react-icons/lia';
import Layout from '../../components/Layout';
import OrderStep from '../../components/Step';
import {
  approvedQuote,
  draftOrderFinished,
  getOneOrder,
  quoteToDraft,
} from '../../store/actions/orderActions';
import {
  orderShippingType,
  orderStatus,
  quoteTypeList,
} from '../../constants/orderStatus';
import TotalAmount from '../../components/order/TotalAmount';
import AppModal from '../../components/AppModal';
import AdditionalChargeList from '../../components/order/AdditionalChargeList';

const ListItem = ({ left, right }) => (
  <div className="order-review-header-list">
    <div className="order-review-header-list-left">{left}:</div>
    <div className="order-review-header-list-right">{right}</div>
  </div>
);

const OrderHeader = ({ order }) => (
  <div className="order-review-header-container">
    <div className="order-review-header-item">
      <div className="order-review-header-icon">
        <VscListUnordered size={30} />
        <span className="order-review-header-title">
          <h3>ORDER INFO</h3>
        </span>
      </div>
      <ListItem left="PO Number" right={order?.poNumber} />
      <ListItem
        left="Draft Created"
        right={moment(order?.createdAt).format('lll')}
      />
      <ListItem left="Draft Created By" right={order?.customer?.company} />
    </div>
    <div className="order-review-header-divider" />
    <div className="order-review-header-item">
      <div className="order-review-header-icon">
        <LiaShippingFastSolid size={30} />
        <span className="order-review-header-title">
          <h3>SHIPPING INFO</h3>
        </span>
      </div>
      {order?.shippingType === orderShippingType.PICK_UP && (
        <>
          <ListItem left="Shipping Metodh" right="Pick Up" />
          <ListItem left="Location" right={order?.warehouse?.name} />
          <ListItem
            left="Address"
            right={`${order?.warehouse?.address}
      ${order?.warehouse?.address1 ? `, ${order?.warehouse?.address1}` : ''}, ${
        order?.warehouse?.city
      }, 
      ${order?.warehouse?.province}, ${order?.warehouse?.postCode} ${
        order?.warehouse?.country
      }`}
          />
        </>
      )}
      {order?.shippingType === orderShippingType.SHIPPING && (
        <>
          <ListItem left="Shipping Metodh" right="Shipping" />
          <ListItem
            left="Name"
            right={`${order?.ordershippingaddress?.firstName} ${order?.ordershippingaddress?.lastName}`}
          />
          <ListItem left="Phone" right={order?.ordershippingaddress?.phone} />
          <ListItem left="Email" right={order?.ordershippingaddress?.email} />
          <ListItem
            left="Address"
            right={`${order?.ordershippingaddress?.address}
      ${
        order?.ordershippingaddress?.address1
          ? `, ${order?.ordershippingaddress?.address1}`
          : ''
      }, ${order?.ordershippingaddress?.city}, 
      ${order?.ordershippingaddress?.province}, ${
        order?.ordershippingaddress?.postCode
      } ${order?.ordershippingaddress?.country}`}
          />
        </>
      )}
    </div>
  </div>
);

const CustomCardMedia = styled(CardMedia)({
  height: 50,
  objectFit: 'contain',
});

const ReviewOrderItemLists = ({ orderItem }) => {
  const finishedEndItem = useMemo(() => {
    if (orderItem?.orderitemfinishedend) {
      return orderItem.orderitemfinishedend;
    }
    return null;
  }, [orderItem]);
  return (
    <>
      <TableRow
        className={
          orderItem?.note ||
          orderItem?.finishedOption ||
          orderItem?.assemblyRequest
            ? 'noBottomBorder'
            : ''
        }>
        <TableCell>{orderItem?.quantity}</TableCell>
        <TableCell component="th" scope="row">
          {orderItem.skuNo}
        </TableCell>
        <TableCell>
          {orderItem.isItem ? 'Item' : orderItem.productcolor.product.name}
        </TableCell>
        <TableCell>
          {orderItem?.productcolor?.product?.doorDirection
            ? orderItem?.doorDirection
            : ''}
        </TableCell>
        <TableCell>
          {orderItem?.productcolor?.finishedEnd ? orderItem.finishedOption : ''}
        </TableCell>
        <TableCell>${orderItem?.price?.toFixed(2)}</TableCell>
        <TableCell>
          ${(orderItem.price * orderItem.quantity).toFixed(2)}
        </TableCell>
      </TableRow>
      {finishedEndItem && (
        <TableRow
          className={
            orderItem?.note || orderItem?.assemblyRequest
              ? 'noBottomBorder'
              : ''
          }>
          <TableCell>{finishedEndItem?.quantity}</TableCell>
          <TableCell component="th" scope="row">
            {finishedEndItem?.item?.itemSKU}
          </TableCell>
          <TableCell>{finishedEndItem?.description}</TableCell>
          <TableCell />
          <TableCell />
          <TableCell>${finishedEndItem?.price?.toFixed(2)}</TableCell>
          <TableCell>
            ${(finishedEndItem.price * finishedEndItem.quantity).toFixed(2)}
          </TableCell>
        </TableRow>
      )}

      {orderItem?.assemblyRequest && (
        <TableRow className={orderItem?.note ? 'noBottomBorder' : ''}>
          <TableCell>{orderItem?.quantity}</TableCell>
          <TableCell component="th" scope="row">
            Assembly Fee
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell>${orderItem?.assemblyFee?.toFixed(2)}</TableCell>
          <TableCell>
            ${((orderItem?.assemblyFee || 0) * orderItem.quantity).toFixed(2)}
          </TableCell>
        </TableRow>
      )}

      {orderItem?.note && (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell colSpan={7}>
            <div className="dot-border">{orderItem.note}</div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export const ReviewOrderStyle = ({ orderStyle }) => (
  <Card className="order-item-card">
    <Grid container spacing={2}>
      <Grid item xs={1}>
        <CustomCardMedia
          component="img"
          image={
            orderStyle?.doorcolor?.picLink ||
            'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
          }
          alt="order pic"
        />
      </Grid>
      <Grid item xs={11}>
        <CardContent>
          <h3>
            {orderStyle?.styleName} ({orderStyle?.doorcolor?.name})
          </h3>
        </CardContent>
      </Grid>
    </Grid>
    {orderStyle?.orderitems?.length > 0 && (
      <CardContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>Qty</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Hinge Side</TableCell>
                <TableCell>Finished End</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>

              {orderStyle?.orderitems?.map(orderItem => (
                <ReviewOrderItemLists
                  orderItem={orderItem}
                  key={orderItem.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    )}
  </Card>
);

const Review = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { order } = useSelector(state => state.order);
  const [modalOpen, setModalOpen] = useState(false);
  const [returnOpen, setReturnOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const handleReturnClose = () => setReturnOpen(false);

  useEffect(() => {
    if (id) dispatch(getOneOrder(id));
  }, [dispatch, id]);

  const handleSubmit = useCallback(() => {
    dispatch(draftOrderFinished(id, navigate));
  }, [dispatch, id, navigate]);

  const handleReturnToDraft = useCallback(() => {
    dispatch(quoteToDraft(id, navigate));
  }, [dispatch, id, navigate]);

  const handleConfirmed = useCallback(() => {
    dispatch(approvedQuote(id, order));
  }, [dispatch, id, order]);

  const isDraft = useMemo(
    () => parseInt(order?.orderType, 10) === orderStatus.DRAFT,
    [order],
  );

  const needConfirm = useMemo(
    () =>
      parseInt(order?.orderType, 10) === orderStatus.QUOTE &&
      order.quoteType === quoteTypeList.SENT_TO_CONFIRM,
    [order],
  );

  return (
    <Layout>
      <Container>
        <div style={{ marginTop: '50px' }} />
        {isDraft && <OrderStep currentStep={3} />}
        <div className="order-products-box">
          <Button variant="outlined" color="grey" onClick={() => navigate(-1)}>
            Back
          </Button>
        </div>
        {order && (
          <div className="order-products-box">
            <OrderHeader order={order} />
            <div style={{ marginTop: '30px' }} />
            <div className="order-product-items">
              {order?.orderstyles?.map(orderStyle => (
                <ReviewOrderStyle key={orderStyle.id} orderStyle={orderStyle} />
              ))}
            </div>
            {order?.orderadditionalcharges?.length > 0 && (
              <AdditionalChargeList
                orderAdditionalCharges={order.orderadditionalcharges}
              />
            )}
            <TotalAmount order={order} />
            <div className="order-review-button">
              {isDraft && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}>
                  Finished Draft
                </Button>
              )}
              {needConfirm && (
                <div
                  style={{
                    marginBottom: '10px',
                    marginTop: '10px',
                  }}>
                  <AppModal
                    open={modalOpen}
                    cancelContent="Cancel"
                    submitContent="Confirm"
                    title="Are you sure you want to CONFIRM Quote?"
                    cancelClick={handleModalClose}
                    submitClick={handleConfirmed}
                    danger={false}
                    handleClose={handleModalClose}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalOpen(true)}>
                    Confirm Quote
                  </Button>
                </div>
              )}
              {parseInt(order?.orderType, 10) === orderStatus.QUOTE &&
                order?.quoteType < 3 && (
                  <div
                    style={{
                      marginBottom: '10px',
                      marginTop: '10px',
                    }}>
                    <AppModal
                      open={returnOpen}
                      cancelContent="Cancel"
                      submitContent="Confirm"
                      title="Are you sure you want to return Order to Draft?"
                      cancelClick={handleReturnClose}
                      submitClick={handleReturnToDraft}
                      danger={false}
                      handleClose={handleReturnClose}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setReturnOpen(true)}>
                      Return to Draft
                    </Button>
                  </div>
                )}
              {!isDraft && order?.quoteType < 4 ? (
                <div
                  style={{
                    marginBottom: '10px',
                    marginTop: '10px',
                  }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => navigate(`/order/printquotation/${id}`)}>
                    Print Quotation
                  </Button>
                </div>
              ) : null}
              {order?.invoiceNo &&
              parseInt(order?.orderType, 10) === orderStatus.SHIPPED ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(`/order/printinvoice/${id}`)}>
                  Print Invoice
                </Button>
              ) : null}
            </div>
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default Review;
