import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import OrderForm from '../../components/order/OrderForm';
import { formTypeList } from '../../constants/orderStatus';
import { getOneOrder } from '../../store/actions/orderActions';

const EditOrder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { order } = useSelector(state => state.order);
  useEffect(() => {
    dispatch(getOneOrder(id));
  }, [dispatch, id]);
  return (
    <Layout>
      {order ? (
        <OrderForm formType={formTypeList.UPDATE} propsState={order} />
      ) : null}
    </Layout>
  );
};

export default EditOrder;
