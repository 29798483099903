import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Menu, MenuItem } from '@mui/material';
import Layout from '../components/Layout';
import {
  getAllSpecBookCategories,
  getSpecBookStyles,
} from '../store/actions/specBookActions';
import ProductLists from '../components/specBook/ProductLists';
import SearchSpecBook from '../components/specBook/SearchSpecBook';

const SpecBookStyleItem = ({
  style,
  categoryId,
  setSelectedStyle,
  handleClose,
}) => {
  const handleStyle = useCallback(() => {
    const values = {
      categoryId,
      ...style,
    };
    setSelectedStyle(values);
    handleClose(null);
  }, [style, categoryId, setSelectedStyle, handleClose]);
  return <MenuItem onClick={handleStyle}>{style.name}</MenuItem>;
};

const SpecBookCategory = ({ category, selectedStyle, setSelectedStyle }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategory = useCallback(() => {
    dispatch(getSpecBookStyles({ categoryId: category.id }));
    setSelectedStyle({
      name: category?.name?.toUpperCase(),
      categoryId: category.id,
    });
    handleClose(null);
  }, [dispatch, category.id, category.name, setSelectedStyle]);
  return (
    <div>
      <Button
        id={`basic-button-${category.id}`}
        color={selectedStyle?.categoryId === category.id ? 'primary' : 'grey'}
        variant={selectedStyle?.categoryId === category.id ? 'outlined' : ''}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        {category.name}
      </Button>
      <Menu
        id={`category-${category.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={handleCategory}>All {category?.name}</MenuItem>
        {category?.productstyles?.map(style => (
          <SpecBookStyleItem
            key={style.id}
            style={style}
            categoryId={category.id}
            setSelectedStyle={setSelectedStyle}
            handleClose={handleClose}
          />
        ))}
      </Menu>
    </div>
  );
};

const SpecBookHeader = ({
  productCategories,
  selectedStyle,
  setSelectedStyle,
}) => (
  <div className="spec-book-header-buttons">
    {productCategories.map(category => (
      <SpecBookCategory
        key={category.id}
        category={category}
        selectedStyle={selectedStyle}
        setSelectedStyle={setSelectedStyle}
      />
    ))}
  </div>
);

function SpecBook() {
  const dispatch = useDispatch();
  const { productCategories } = useSelector(state => state.specBook);
  const [selectedStyle, setSelectedStyle] = useState(null);

  useEffect(() => {
    dispatch(getAllSpecBookCategories());
  }, [dispatch]);
  return (
    <Layout>
      <Container>
        <SearchSpecBook setSelectedStyle={setSelectedStyle} />
        {productCategories && (
          <SpecBookHeader
            productCategories={productCategories}
            selectedStyle={selectedStyle}
            setSelectedStyle={setSelectedStyle}
          />
        )}
        {selectedStyle && (
          <ProductLists
            selectedStyle={selectedStyle}
            styleId={selectedStyle?.id}
          />
        )}
      </Container>
    </Layout>
  );
}

export default SpecBook;
