import { CREATE_MESSAGE } from '../types';

const initialState = {
  message: null,
};
// eslint-disable-next-line default-param-last
const messageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_MESSAGE:
      return {
        ...state,
        message: payload,
      };
    default:
      return state;
  }
};

export default messageReducer;
