import React, { useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Sidebar from './SideBar';

function Layout({ children }) {
  const navigate = useNavigate();
  const { userInfo } = useSelector(state => state.auth);
  // const { mode } = useSelector((state) => state.mode)
  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }
  }, [userInfo, navigate]);

  return (
    <div>
      <Box bgcolor="background.default" color="text.primary">
        <Navbar />
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          minHeight="95vh">
          <Sidebar userInfo={userInfo} />
          <Box flex={4}>{children}</Box>
        </Stack>
      </Box>
    </div>
  );
}

export default Layout;
