/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  ListItemIcon,
  MenuItem,
  Modal,
} from '@mui/material';
import Layout from '../../components/Layout';
import OrderStep from '../../components/Step';
import {
  deleteCustomerShippingAddress,
  getAllCustomerShippingAddresses,
  getAllWarehouses,
  getOneOrder,
  orderPickUp,
  orderShippingAddress,
} from '../../store/actions/orderActions';
import CreateCustomerShippingAddress from '../../components/order/CreateCustomerShippingAddress';
import AppModal from '../../components/AppModal';
import EditIcon from '../../components/order/EditIcon';
import UpdateCustomerShippingAddress from '../../components/order/UpdateCustomerShippingAddress';
import { orderShippingType } from '../../constants/orderStatus';

const CardHeaderIcon = ({ address }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModelOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const handleDelete = useCallback(() => {
    dispatch(deleteCustomerShippingAddress(address.id));
  }, [address.id, dispatch]);
  const handleEdit = useCallback(() => {
    setEditOpen(true);
  }, []);
  return (
    <>
      <Modal
        open={editOpen}
        // onClose={handleClose}
        aria-labelledby="add-customer-shipping-address"
        aria-describedby="add-customer-shipping-address">
        <div>
          <UpdateCustomerShippingAddress
            modalClose={setEditOpen}
            editProps={address}
          />
        </div>
      </Modal>
      <AppModal
        open={modalOpen}
        cancelContent="Cancel"
        submitContent="Delete"
        title="Are you sure you want to delete"
        cancelClick={handleModalClose}
        submitClick={handleDelete}
        danger
        handleClose={handleModalClose}
      />
      <EditIcon
        handleClick={handleClick}
        handleClose={handleClose}
        title="Order Item"
        anchorEl={anchorEl}>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <FaEdit fontSize={24} />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem onClick={handleModelOpen}>
          <ListItemIcon>
            <MdDeleteForever fontSize={24} style={{ color: 'red' }} />
          </ListItemIcon>
          Delete
        </MenuItem>
      </EditIcon>
    </>
  );
};

const ShippingAddressDetails = ({ address }) => (
  <>
    <div>
      Name: {address?.firstName} {address?.lastName}
    </div>
    <div>Phone Number: {address?.phone}</div>
    <div>Email: {address?.email}</div>
    <div>
      Address: {address?.address}
      {address?.address1 ? `, ${address?.address1}` : ''}, {address?.city},{' '}
      {address?.province}, {address?.postCode} {address?.country}
    </div>
  </>
);

const SippingaAddressBox = ({
  address,
  selectedAddress,
  setSelectedAddress,
}) => {
  const handleClick = useCallback(
    customerAddress => {
      setSelectedAddress(customerAddress);
    },
    [setSelectedAddress],
  );

  const active = useMemo(
    () => selectedAddress?.id === address.id,
    [selectedAddress?.id, address.id],
  );

  return (
    <div
      className={`shipping-address-box ${
        active ? 'shipping-address-box-active' : null
      }`}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CardHeaderIcon address={address} />
      </div>
      <div
        className="shipping-address-details"
        onClick={() => handleClick(address)}>
        <ShippingAddressDetails address={address} />
      </div>
    </div>
  );
};

const ShippingMethod = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerShippingAddresses, order } = useSelector(
    state => state.order,
  );

  const [createOpen, setCreateOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    dispatch(getAllCustomerShippingAddresses());
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    const values = {
      ...selectedAddress,
      shippingType: orderShippingType.SHIPPING,
      customerShippingAddressId: selectedAddress?.id,
      orderId: order?.id,
    };
    dispatch(orderShippingAddress(values, navigate));
  }, [dispatch, navigate, order?.id, selectedAddress]);

  return (
    <>
      <Modal
        open={createOpen}
        aria-labelledby="add-customer-shipping-address"
        aria-describedby="add-customer-shipping-address">
        <div>
          <CreateCustomerShippingAddress modalClose={setCreateOpen} />
        </div>
      </Modal>
      {selectedAddress && (
        <div className="order-shipping-selected">
          <Divider>
            <h3>Selected Shipping Address</h3>
          </Divider>
          <ShippingAddressDetails address={selectedAddress} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}>
            Review
          </Button>
          <Divider />
        </div>
      )}
      <div className="order-products-box">
        <Button variant="outlined" onClick={() => setCreateOpen(true)}>
          Add New Shipping Address
        </Button>
        {customerShippingAddresses?.map(address => (
          <SippingaAddressBox
            key={address.id}
            address={address}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
          />
        ))}
      </div>
    </>
  );
};

const WarehouseItem = ({ warehouse, selectWarehouse, setSelectWarehouse }) => {
  const handleClick = useCallback(
    currentWarehouse => {
      setSelectWarehouse(currentWarehouse);
    },
    [setSelectWarehouse],
  );

  const active = useMemo(
    () => selectWarehouse?.id === warehouse?.id,
    [warehouse?.id, selectWarehouse?.id],
  );

  return (
    <div
      onClick={() => (active ? null : handleClick(warehouse))}
      className={`order-pickup-box ${
        active ? 'order-pickup-box-active' : null
      }`}>
      <div>Name: {warehouse?.name}</div>
      <div>
        Address: {warehouse?.address}
        {warehouse?.address1 ? `, ${warehouse?.address1}` : ''},{' '}
        {warehouse?.city}, {warehouse?.province}, {warehouse?.postCode}{' '}
        {warehouse?.country}
      </div>
    </div>
  );
};

const PickUpMethod = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { warehouses, order } = useSelector(state => state.order);
  const [selectWarehouse, setSelectWarehouse] = useState(null);
  useEffect(() => {
    dispatch(getAllWarehouses());
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    const values = {
      shippingType: orderShippingType.PICK_UP,
      warehouseId: selectWarehouse?.id,
      id: order?.id,
    };
    dispatch(orderPickUp(values, navigate));
  }, [dispatch, navigate, selectWarehouse?.id, order?.id]);

  return (
    <div className="order-products-box">
      {warehouses?.map(warehouse => (
        <WarehouseItem
          key={warehouse.id}
          warehouse={warehouse}
          selectWarehouse={selectWarehouse}
          setSelectWarehouse={setSelectWarehouse}
        />
      ))}
      {selectWarehouse && (
        <div className="order-pickup-button">
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => handleSubmit()}>
            Review
          </Button>
        </div>
      )}
    </div>
  );
};

const Shipping = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { order } = useSelector(state => state.order);
  const [shippingType, setShippingType] = useState(
    () => order?.shippingType || orderShippingType.SHIPPING,
  );

  useEffect(() => {
    if (id) dispatch(getOneOrder(id));
  }, [dispatch, id]);
  return (
    <Layout>
      <Container>
        <div style={{ marginTop: '50px' }} />
        <OrderStep currentStep={2} />
        <div className="order-products-box">
          <Button variant="outlined" color="grey" onClick={() => navigate(-1)}>
            Back
          </Button>
        </div>
        <div className="order-shipping-slider">
          <Button
            color={`${
              shippingType === orderShippingType.SHIPPING ? 'primary' : 'grey'
            }`}
            onClick={() => setShippingType(orderShippingType.SHIPPING)}>
            Shipping
          </Button>
          <Button
            color={`${
              shippingType === orderShippingType.PICK_UP ? 'primary' : 'grey'
            }`}
            onClick={() => setShippingType(orderShippingType.PICK_UP)}>
            Pick Up
          </Button>
        </div>
        <div className="order-products-box">
          {shippingType === orderShippingType.SHIPPING && <ShippingMethod />}
          {shippingType === orderShippingType.PICK_UP && <PickUpMethod />}
        </div>
      </Container>
    </Layout>
  );
};

export default Shipping;
