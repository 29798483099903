import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import Loading from './components/loading/Loading';
import Login from './pages/Login';
import SignIn from './pages/SignIn';
import Alert from './components/Alert';
import SigninSuccess from './pages/SigninSuccess';
import ConfirmEmail from './pages/ConfirmEmail';
import Launch from './pages/order/Launch';
import SpecBook from './pages/SpecBook';
import NewDraft from './pages/order/NewDraft';
import Products from './pages/order/Products';
import NotFound from './pages/NotFound';
import ForgetPassword from './pages/ForgetPassword';
import ResetForgotPassword from './pages/ResetForgotPassword';
import EditOrder from './pages/order/EditOrder';
import Shipping from './pages/order/Shipping';
import Review from './pages/order/Review';
import PrintInvoice from './pages/order/PrintInvoice';
import PrintQuotation from './pages/order/PrintQuotation';

function App() {
  return (
    <>
      <Alert />
      <Loading />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/order" element={<Launch />} />
        <Route path="/order/newdraft" element={<NewDraft />} />
        <Route path="/order/editorder/:id" element={<EditOrder />} />
        <Route path="/order/shipping/:id" element={<Shipping />} />
        <Route path="/order/products/:id" element={<Products />} />
        <Route path="/order/review/:id" element={<Review />} />
        <Route path="/order/printinvoice/:id" element={<PrintInvoice />} />
        <Route path="/order/printquotation/:id" element={<PrintQuotation />} />
        <Route path="/specbook" element={<SpecBook />} />
        <Route path="/signinsuccess/:id" element={<SigninSuccess />} />
        <Route path="/confirmemail/:token" element={<ConfirmEmail />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/resetpassword/:token" element={<ResetForgotPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
