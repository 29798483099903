import React from 'react';
import { MdOutlineDoNotDisturbAlt } from 'react-icons/md';
import { Container } from '@mui/material';
import Layout from '../components/Layout';
import colorCode from '../constants/colorCode';

function NotFound() {
  return (
    <Layout>
      <Container>
        <div className="notfound-box">
          <MdOutlineDoNotDisturbAlt size="10em" />
          <h1 style={{ color: colorCode.PRIMARY }}>404 - Not Found</h1>
          <div>Oops! The page you are looking for does not exist.</div>
        </div>
      </Container>
    </Layout>
  );
}

export default NotFound;
