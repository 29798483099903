export const SHOW_LOADING = 'SHOW_LOADING';
export const USER_LOGOIN_SUCCESS = 'USER_LOGOIN_SUCCESS';
export const USER_UPDATE_TOKEN = 'USER_UPDATE_TOKEN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const RESEND_CONFIRM_EMAIL = 'RESEND_CONFIRM_EMAIL';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_ORDER_COUNTS = 'GET_ORDER_COUNTS';
export const UPDATE_SIDE_BAR = 'UPDATE_SIDE_BAR';
export const GET_ONE_ORDER = 'GET_ONE_ORDER';
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const UPDATE_ALL_ORDERS = 'UPDATE_ALL_ORDERS';
export const GET_SEARCH_RPODUCT = 'GET_SEARCH_RPODUCT';
export const UPDATE_EMAIL_SENT_COUNT = 'UPDATE_EMAIL_SENT_COUNT';
export const GET_ALL_CUSTOMER_SHIPPING_ADDRESSES =
  'GET_ALL_CUSTOMER_SHIPPING_ADDRESSES';
export const CREATE_CUSTOMER_SHIPPING_ADDRESS =
  'CREATE_CUSTOMER_SHIPPING_ADDRESS';
export const UPDATE_CUSTOMER_SHIPPING_ADDRESS =
  'UPDATE_CUSTOMER_SHIPPING_ADDRESS';
export const DELETE_CUSTOMER_SHIPPING_ADDRESS =
  'DELETE_CUSTOMER_SHIPPING_ADDRESS';
export const GET_ALL_WAREHOUSES = 'GET_ALL_WAREHOUSES';

export const GET_CURRENT_DRAFTS = 'GET_CURRENT_DRAFTS';
export const GET_OPEN_QUOTES = 'GET_OPEN_QUOTES';

export const GET_ALL_PRODUCT_CATEGORIES = 'GET_ALL_PRODUCT_CATEGORIES';
export const GET_STYLE_PRODUCTS = 'GET_STYLE_PRODUCTS';
export const GET_ONE_PRODUCT = 'GET_ONE_PRODUCT';
export const GET_AGENT_INFO = 'GET_AGENT_INFO';
