import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import configStore from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/scss/base.scss';
import colorCode from './constants/colorCode';

const { store, persistor } = configStore();

const theme = createTheme({
  palette: {
    primary: {
      main: colorCode.PRIMARY,
      contrastText: '#fff',
    },
    secondary: {
      main: colorCode.SCONDARY,
    },
    grey: {
      main: colorCode.GREY,
    },
  },
});

// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
