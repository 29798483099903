/* eslint-disable no-unused-vars */
import React from 'react';

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  // Switch,
  // Collapse,
  // Button,
} from '@mui/material';
// import { FiMenu } from 'react-icons/fi';
import { BiSolidDashboard } from 'react-icons/bi';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { BsBookHalf } from 'react-icons/bs';
// import { BsBookHalf } from 'react-icons/bs';
// import { AiOutlineUnorderedList } from 'react-icons/ai';
// import { BiSolidDashboard, BiLogOut } from 'react-icons/bi';
import { Link, useLocation } from 'react-router-dom';
import colorCode from '../constants/colorCode';
// import { useDispatch, useSelector } from 'react-redux';

function extractPath(url) {
  const regex = /\/order\/products\//;
  const match = url.match(regex);

  if (match) {
    return match[0];
  }
  return url;
}

export default function Sidebar() {
  // const dispatch = useDispatch();

  const location = useLocation();

  return (
    <Box
      // flex={1}
      // sx={{ display: { xs: 'none', sm: 'block' } }}
      style={{ backgroundColor: '#f6f6f6', width: '180px', padding: '5px' }}>
      <Box position="fixed">
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/">
              <ListItemIcon>
                <BiSolidDashboard color={colorCode.PRIMARY} size={28} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/order">
              <ListItemIcon>
                <AiOutlineUnorderedList color={colorCode.PRIMARY} size={28} />
              </ListItemIcon>
              <ListItemText primary="Orders" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/specbook">
              <ListItemIcon>
                <BsBookHalf color={colorCode.PRIMARY} size={28} />
              </ListItemIcon>
              <ListItemText primary="Spec Book" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
}

// {/*
// <div className="border-t border-gray-300 mt-2" />
//           <div className="flex-1">
//             <ul className="pt-2 pb-4 space-y-1 text-sm">
//               <li className={`rounded-sm h-10 ${isLinkActive(['/'])}`}>
//                 <Link
//                   to="/"
//                   className="flex items-center p-2 space-x-3 rounded-md">
//                   <BiSolidDashboard className="text-white w-5 h-5" />
//                   {sideBarOpen && (
//                     <span className="text-gray-100">Dashboard</span>
//                   )}
//                 </Link>
//               </li>
//               <li
//                 className={`rounded-sm ${isLinkActive([
//                   '/order',
//                   '/order/newdraft',
//                   '/order/products/',
//                 ])}`}>
//                 <Link
//                   to="/order"
//                   className="flex items-center p-2 space-x-3 rounded-md">
//                   <AiOutlineUnorderedList className="text-white  w-5 h-5" />
//                   {sideBarOpen && <span className="text-gray-100">Orders</span>}
//                 </Link>
//               </li>

//               <li className={`rounded-sm ${isLinkActive(['/specbook'])}`}>
//                 <Link
//                   to="/specbook"
//                   className="flex items-center p-2 space-x-3 rounded-md">
//                   <BsBookHalf className="text-white  w-5 h-5" />
//                   {sideBarOpen && (
//                     <span className="text-gray-100">Spec Book</span>
//                   )}
//                 </Link>
//               </li>
//             </ul>
//           </div>
//         </div>
//         <List className="">
//           {/* "Logout" part at the bottom */}
//           <div className="border-t border-gray-300 mt-2" />
//           <Button
//             className="flex items-center p-2 space-x-3 rounded-md cursor-pointer"
//             onClick={() => setShowAlert(true)}>
//             <BiLogOut className="text-white  w-5 h-5" />
//             {sideBarOpen && <span className="text-gray-100">Logout</span>}
//           </Button>

//           {/* End of "Logout" part */}
//         </List> */}
