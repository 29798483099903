import errorsReturn from '../errorsReturn';
import { GET_ALL_PRODUCT_CATEGORIES, GET_STYLE_PRODUCTS } from '../types';
import { controlLoading } from './loadingActions';
import getErrors from './errorActions';
import request from '../../config/request';
import { logout, renewUserToken } from './authActions';

export const getAllSpecBookCategories = () => async (dispatch, getState) => {
  try {
    dispatch(controlLoading(true));
    const {
      auth: { userToken },
    } = getState();

    const res = await request.get(`order/specbook`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    dispatch(renewUserToken(res));

    dispatch({
      type: GET_ALL_PRODUCT_CATEGORIES,
      payload: res.data.data,
    });
    dispatch(controlLoading(false));
  } catch (e) {
    errorsReturn(e, dispatch, controlLoading, getErrors, logout);
  }
};

export const getSpecBookStyles =
  (values, keyword = '') =>
  async (dispatch, getState) => {
    try {
      dispatch(controlLoading(true));
      const {
        auth: { userToken },
      } = getState();

      const res = await request.post(
        `order/specbookstyle?keyword=${keyword}`,
        values,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        },
      );
      dispatch(renewUserToken(res));
      dispatch({
        type: GET_STYLE_PRODUCTS,
        payload: res.data.data,
      });
      dispatch(controlLoading(false));
    } catch (e) {
      errorsReturn(e, dispatch, controlLoading, getErrors, logout);
    }
  };
