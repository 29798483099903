import React from 'react';
import { useSelector } from 'react-redux';
import Loader from './Loader';

export function LoadingSet() {
  const loading = useSelector(state => state.loading);
  const { showLoading } = loading;
  return <div>{showLoading && <Loader />}</div>;
}

export default LoadingSet;
