import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSpecBookStyles } from '../../store/actions/specBookActions';
import OrderSearch from '../order/OrderSearch';

const SearchSpecBook = ({ setSelectedStyle }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const keyword = queryParams.get('keyword') || '';

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (search) {
      navigate(`?keyword=${search}`);
    }
  }, [search, navigate]);

  const fetchDate = useCallback(() => {
    if (keyword) {
      dispatch(getSpecBookStyles('', keyword));
      setSelectedStyle({ name: 'Search' });
    }
  }, [dispatch, keyword, setSelectedStyle]);

  useEffect(() => {
    fetchDate();
  }, [fetchDate]);

  return (
    <div>
      <OrderSearch setkeyword={setSearch} />
    </div>
  );
};

export default SearchSpecBook;
