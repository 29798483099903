const errorsReturn = (e, dispatch, controlLoading, getErrors, logout) => {
  const errors = {
    msg: e?.response?.data?.msg,
  };
  dispatch(controlLoading(false));

  if (errors?.msg === 'JWT Failed') {
    dispatch(logout());
  } else {
    dispatch(getErrors(errors?.msg));
  }
};

export default errorsReturn;
