import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { siteKey } from '../config/default';
import AppForm from '../components/form/AppForm';
import AppInput from '../components/form/AppInput';
import SubmitButton from '../components/form/SubmitButton';
import { resetForgetPassword } from '../store/actions/authActions';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required()
    .min(8, 'Must be at least 4 characters')
    .label('Password'),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .label('Confirm Password'),
});

const ResetForgotPassword = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  return (
    <Container>
      <div className="login-container">
        <div className="login-box">
          <h2 style={{ textAlign: 'center', padding: '20px' }}>
            Reset Password
          </h2>
          <AppForm
            initialValues={{ password: '', confirmPassword: '', token }}
            onSubmit={async values => {
              const googleRes = await recaptchaRef?.current?.executeAsync();
              recaptchaRef?.current?.reset();
              const updateValues = { ...values, googleRes };
              dispatch(resetForgetPassword(updateValues, navigate));
            }}
            validationSchema={validationSchema}>
            <AppInput
              name="password"
              label="Password"
              type="password"
              required
            />
            <AppInput
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              required
            />
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={siteKey} />
            <SubmitButton title="Update Password" />
          </AppForm>
        </div>
      </div>
    </Container>
  );
};

export default ResetForgotPassword;
