import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Paper } from '@mui/material';
import { getCurrentDrafts } from '../../store/actions/mainActions';
import OrderItem from './OrderItem';
import { getCategories } from '../../store/actions/orderActions';

const AddNewButtons = ({ currentDraftsLength }) => (
  <div className="recent-drafts-last-area">
    <Link to="/order/newdraft">
      <Button variant="contained" color="primary">
        New Draft
      </Button>
    </Link>
    {currentDraftsLength ? (
      <Link to="order?status=1">
        <Button variant="outlined" color="grey">
          View All
        </Button>
      </Link>
    ) : null}
  </div>
);

const RecentOrders = () => {
  const dispatch = useDispatch();
  const { currentDrafts } = useSelector(state => state.main);
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getCurrentDrafts());
  }, [dispatch]);
  return (
    <div className="main-recent-drafts-container">
      <h3 style={{ padding: '10px' }}>RECENT DRAFTS</h3>
      {currentDrafts?.length > 0 ? (
        <div className="main-recent-drafts-box">
          {currentDrafts?.map(order => (
            <OrderItem key={order.id} order={order} />
          ))}
          <AddNewButtons currentDraftsLength={currentDrafts?.length} />
        </div>
      ) : (
        <div className="main-recent-no-founds">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                width: 600,
                height: 200,
              },
            }}>
            <Paper className="main-recent-no-founds-content">
              You have no recent drafts.
            </Paper>
          </Box>
          <AddNewButtons currentDraftsLength={currentDrafts?.length} />
        </div>
      )}
    </div>
  );
};

export default RecentOrders;
