import React, { useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getOpenQuotes } from '../../store/actions/mainActions';
import OrderItem from './OrderItem';

const OpenQuotes = () => {
  const dispatch = useDispatch();
  const { openQuotes } = useSelector(state => state.main);
  useEffect(() => {
    dispatch(getOpenQuotes());
  }, [dispatch]);
  return (
    <div className="main-recent-drafts-container">
      <h3 style={{ padding: '10px' }}>OPEN QUOTES</h3>
      {openQuotes?.length > 0 ? (
        <div className="main-recent-drafts-box">
          {openQuotes?.map(order => (
            <OrderItem key={order.id} order={order} />
          ))}
        </div>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 1,
              width: 800,
              height: 200,
            },
          }}>
          <Paper className="main-recent-no-founds-content">
            You have no open quotes.
          </Paper>
        </Box>
      )}
    </div>
  );
};

export default OpenQuotes;
