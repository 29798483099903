import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/Layout';
import OrderForm from '../../components/order/OrderForm';
import { formTypeList } from '../../constants/orderStatus';
import { getAgentInfo } from '../../store/actions/orderActions';

function NewDraft() {
  const dispatch = useDispatch();
  const { agentInfo } = useSelector(state => state.order);
  useEffect(() => {
    if (!agentInfo) {
      dispatch(getAgentInfo());
    }
  }, [dispatch, agentInfo]);
  return (
    <Layout>
      {agentInfo ? (
        <OrderForm
          formType={formTypeList.NEW}
          propsState={{
            ...agentInfo,
            clientName: `${agentInfo.firstName} ${agentInfo.lastName}`,
            clientEmail: agentInfo.email,
          }}
        />
      ) : null}
    </Layout>
  );
}

export default NewDraft;
