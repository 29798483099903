import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Alert() {
  const errors = useSelector(state => state.errors);
  const messages = useSelector(state => state.messages);

  useEffect(() => {
    if (errors.msg) {
      toast.error(errors.msg);
    }

    if (messages.message) {
      toast.success(messages.message);
    }
  }, [errors, messages]);

  return (
    <div>
      <ToastContainer />
    </div>
  );
}

export default Alert;
