import React from 'react';
import AddressForm from './AddressForm';
import { formTypeList } from '../../constants/orderStatus';

const CreateCustomerShippingAddress = ({ modalClose }) => (
  <div className="order-shipping-address-box">
    <AddressForm formType={formTypeList.NEW} modalClose={modalClose} />
  </div>
);

export default CreateCustomerShippingAddress;
