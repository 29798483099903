/* eslint-disable no-unused-vars */
import { Button, Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import request from '../../config/request';
import { addOrderSingleItem } from '../../store/actions/orderActions';

const SearchItem = ({ orderStyleId, orderId, colorCode, itemQty }) => {
  const dispatch = useDispatch();
  const [searchItems, setSearchItems] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [controller, setController] = useState(new AbortController());

  useEffect(() => () => controller.abort(), [controller]);

  const handleSearchItemChange = useCallback(option => {
    if (option) {
      setSelectValue(option);
      setItemId(option?.value);
    } else {
      setSelectValue(null);
      setItemId(null);
    }
  }, []);

  const handleInputChange = useCallback(
    searchKeyword => {
      const searchValues = { searchKeyword, colorCode };
      controller.abort();
      const newController = new AbortController();
      setController(newController);
      request
        .post('order/searchitem', searchValues, {
          signal: newController.signal,
        })
        .then(res => {
          if (res.data.data) {
            const selectValues = res?.data?.data?.map(item => ({
              value: item.id,
              label: item.itemSKU,
            }));
            setSearchItems(selectValues);
          }
        })
        .catch(error => {
          if (error.name === 'AbortError') {
            console.log('Search request aborted');
          } else {
            console.error('Error fetching search items:', error);
          }
        });
    },
    [colorCode, controller],
  );

  const handleAddItem = useCallback(() => {
    if (!itemId) return false;
    if (quantity <= 0) return false;
    const values = {
      name: '',
      quantity,
      sort: itemQty + 1,
      doorDirection: '',
      finishedOption: '',
      note: '',
      orderStyleId,
      itemId,
    };
    dispatch(addOrderSingleItem(values, orderStyleId, orderId));
    setQuantity(1);
    setSearchItems([]);
    setItemId(null);
    setSelectValue(null);
    return true;
  }, [dispatch, quantity, itemId, itemQty, orderId, orderStyleId]);

  return (
    <div style={{ marginTop: '50px' }}>
      <p>Add Item</p>
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Grid item xs={6}>
          <Select
            name="searchItem"
            value={selectValue}
            onChange={handleSearchItemChange}
            onInputChange={handleInputChange}
            options={searchItems}
            placeholder="Search Item"
            isClearable
            isSearchable
            style={{ zIndex: 9 }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            size="small"
            name="quantity"
            type="number"
            id={Math.random().toString()}
            label="Quantity"
            variant="outlined"
            value={quantity}
            onChange={e => setQuantity(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleAddItem}>
            Add
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchItem;
