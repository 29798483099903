import React from 'react';
import { printType } from '../../constants/orderStatus';

const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth';

const InvoiceFooter = ({ type, memoOrder }) => (
  <div>
    <div>
      <div>Payment Options:</div>
      <p>1) Cheque, $45 service charge levied on all returned cheques.</p>
      <p>
        2){' '}
        {CLIENT === 'woodworth'
          ? 'Email transfer to sales@de-valor.ca.'
          : 'Zelle transfer to sales@dw-cabinetry.com'}
      </p>
      <p>
        3) Credit Card payment is subject to{' '}
        {CLIENT === 'woodworth' ? '2.0%' : '3.0%'} of surcharge.
      </p>
      <p>4) Debit Card no Charge.</p>
    </div>
    {type === printType.INVOICE && CLIENT === 'woodworth' && !memoOrder && (
      <div style={{ marginTop: '20px' }}>
        <h4 style={{ textAlign: 'center' }}>GST/HST No. 750447492</h4>
      </div>
    )}
  </div>
);

export default InvoiceFooter;
