import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import request from '../config/request';
import getErrors from '../store/actions/errorActions';
import createMessage from '../store/actions/messageActions';

function ConfirmEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const res = await request.get(`customer/confirmation/${token}`);
        if (res.data.code === 200) {
          dispatch(createMessage('Email confirmed! Waiting for approval'));
          navigate(`/login`);
        }
      } catch (e) {
        let displayError = 'Email confirm failed';
        if (e?.response?.data?.msg) {
          displayError = e?.response?.data?.msg;
        }
        dispatch(getErrors(displayError));
      }
    };
    confirmEmail();
  }, [token, navigate, dispatch]);

  return (
    <Container>
      <div className="login-container">
        <Paper
          className="login-box"
          style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
          <div style={{ textAlign: 'center' }}>
            <h3>We are checking your account...</h3>
          </div>
        </Paper>
      </div>
    </Container>
  );
}

export default ConfirmEmail;
