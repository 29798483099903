/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable  no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { FaFilePdf } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import getErrors from '../../store/actions/errorActions';

const acceptedFileTypes = {
  'image/*': ['.jpeg', '.png', '.jpg'],
  'application/pdf': ['.pdf'],
};

function DragDrop({ file, setFile, label, required }) {
  const dispatch = useDispatch();
  const [preview, setPreview] = useState('');
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        dispatch(getErrors('Invalid file format'));
      } else {
        const selectedFile = acceptedFiles[0];
        if (selectedFile?.size && selectedFile.size > 5242880) {
          dispatch(getErrors('Over size! Max 5M'));
        } else {
          setFile(selectedFile);
          setPreview(URL.createObjectURL(selectedFile));
        }
      }
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (file) {
        URL.revokeObjectURL(preview);
      }
    },
    [file, preview],
  );

  return (
    <section className="drag-drop-container">
      {file && (
        <div className="drag-drop-input-delete-box">
          <div
            className="drag-drop-input-delete"
            onClick={() => {
              setFile(null);
              setPreview('');
            }}>
            <IoIosCloseCircleOutline size={25} style={{ color: '#ffffff' }} />
          </div>
        </div>
      )}
      <div {...getRootProps()} className="drag-drop-input">
        <input {...getInputProps()} />
        {file ? (
          <div className="drag-drop-input-pic">
            {file.type.startsWith('image/') ? (
              <img src={preview} alt="pic" className="drag-drop-pic" />
            ) : file.type === 'application/pdf' ? ( // Check if it's a PDF
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '5px',
                }}>
                <FaFilePdf size={60} />
              </div>
            ) : null}
          </div>
        ) : (
          <div className="drag-drop-input-text">
            <p
              style={{
                fontWeight: '600',
                textAlign: 'center',
                marginBottom: '10px',
              }}>
              {label}
              {required ? ' *' : null}
            </p>
            <p>Drag & Drop or Browse</p>
          </div>
        )}
      </div>
    </section>
  );
}

export default DragDrop;
