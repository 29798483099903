import { combineReducers } from 'redux';
import loadingReducer from './loadingReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import orderReducer from './orderReducer';
import messageReducer from './messageReducer';
import mainReducer from './mainReducer';
import specBookReducer from './specBookReducer';

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  messages: messageReducer,
  loading: loadingReducer,
  order: orderReducer,
  main: mainReducer,
  specBook: specBookReducer,
});
