import {
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';

const AdditionalChargeItem = ({ additionalCharge }) => (
  <TableRow>
    <TableCell>{additionalCharge?.item}</TableCell>
    <TableCell>${additionalCharge?.amount?.toFixed(2)}</TableCell>
    <TableCell>{additionalCharge?.note}</TableCell>
  </TableRow>
);

const AdditionalChargeList = ({ orderAdditionalCharges }) => (
  <div style={{ marginTop: '30px' }}>
    <Card className="order-item-card">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardContent>
            <h3>Additional Charges</h3>
          </CardContent>
        </Grid>
      </Grid>
      <CardContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Note</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderAdditionalCharges?.map(additionalCharge => (
                <AdditionalChargeItem
                  additionalCharge={additionalCharge}
                  key={additionalCharge.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </div>
);

export default AdditionalChargeList;
