import { SHOW_LOADING, UPDATE_SIDE_BAR } from '../types';

const initialState = {
  showLoading: false,
  sideBarOpen: false,
};
// eslint-disable-next-line default-param-last
const loadingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LOADING:
      return {
        ...state,
        showLoading: payload,
      };
    case UPDATE_SIDE_BAR:
      return {
        ...state,
        sideBarOpen: payload,
      };
    default:
      return state;
  }
};
export default loadingReducer;
