export const orderStatus = {
  CANCELLED: 0,
  DRAFT: 1,
  QUOTE: 2,
  IN_PROGRESS: 3,
  READY: 4,
  SHIPPED: 5,
};

export const quoteTypeList = {
  NEW: 1,
  SENT_TO_CONFIRM: 2,
  CONFIRMED: 3,
  PAID: 4,
};

export const orderHeaderList = [
  { id: 1, title: 'Drafts' },
  { id: 2, title: 'Quotes' },
  { id: 3, title: 'In-Prduction' },
  { id: 4, title: 'Ready' },
  { id: 5, title: 'Shipped/Picked Up' },
  { id: null, title: 'All Orders' },
];

export const formTypeList = {
  NEW: 'new',
  UPDATE: 'update',
};

export const orderShippingType = {
  SHIPPING: 'shipping',
  PICK_UP: 'pickUp',
};

export const printType = {
  QUOTATION: 'quotation',
  INVOICE: 'invoice',
};
